import React, { useState, useEffect, CSSProperties } from 'react';
import axios from 'axios';
import './TweetCard.css';
import './TextField.css';

import ClipLoader from "react-spinners/ClipLoader";

const override = {
  display: "block",
  margin: "0 auto",
  borderColor: "red",
};

function App() {
  const [tweetData, setTweetData] = useState(null);
  const [snapshotId, setSnapshotId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const [count, setCount] = useState(1);
  const [shouldReset, setShouldReset] = useState(false);

  const [urlTarget, setUrlTarget] = useState('https://X.com/SwedishPM');
  const [prompt, setPrompt] = useState('Is this tweet relevant to my small startup Northvolt that creates batteries?');

  const [tweetAnalysis, setTweetAnalysis] = useState({123: "asdf"})

  const handleChange = (event) => {
    setUrlTarget(event.target.value);
};

  // Countdown logic
  useEffect(() => {
    if (count > 0) {
      const timer = setTimeout(() => setCount(count - 1), 1000); // Decrease the count by 1 every second
      return () => clearTimeout(timer); // Cleanup the timer
    }

    if (count === 0) {
      fetchSnapshotData(); // When the countdown reaches 0, make an API call
    }
  }, [count]);

  useEffect(() => {
    if (tweetData) {
      console.log("Updated tweetData: ", tweetData);
      analyzeTweets(tweetData);
    }
  }, [tweetData]);

  const handleScrapeTweet = async () => {
    // const tweetUrl = "https://X.com/SwedishPM";  // Example tweet URL
    const tweetUrl = urlTarget
    setLoading(true);
    setError('');

    try {
      // const response = await axios.post('http://127.0.0.1:8000/scrape-tweet/', {
      const response = await axios.post('http://16.170.225.162/start_scraping/', {
        url: tweetUrl,
      });
      console.log(response);
      setSnapshotId(response.data["snapshot_id"]);
      setShouldReset(true)
      setCount(10)
    } catch (err) {
      setError('Failed to start scrape');
    } finally {
      setLoading(false);
    }
  };


  const fetchSnapshotData = async () => {
    try {
      // const response = await axios.post('http://127.0.0.1:8000/scrape-tweet/', {
      const response = await axios.post('http://16.170.225.162/fetch_data/', {
        id: snapshotId,
      });
      console.log("Fetched snapsht data: ", response);
      if (response.data["response"]["status"] === 'running'){
        setShouldReset(true);
        setCount(10); // Reset the countdown
      } else if (Array.isArray(response.data["response"])){
        setTweetData(response.data["response"]); // Process and set new variables
        // analyzeTweets(response.data["response"])
        setShouldReset(false); // Stop resetting countdown if condition fails
        console.log(tweetData)
      }
    } catch (err) {
      setError('Failed to fetch tweet');
      console.error(err);
    } finally {
      setLoading(false);
    }
  }

  const analyzeTweets = async () => {
    console.log("tweet data is: ", tweetData, typeof(tweetData))
    try {
      for (const tweet of tweetData) {
        try {
          // const response = await axios.post('http://127.0.0.1:8000/scrape-tweet/', {
          const response = await axios.post('http://16.170.225.162/get_analysis/', {
            prompt: prompt,
            text: tweet.description
          });
          console.log("Fetched analysis data: ", response);
          if (response.data["response"]){
            setTweetAnalysis(prevAnalysis => ({
              ...prevAnalysis,
              [tweet.id]: response.data["response"]
            }));
          }
        } catch (err) {
          setError('Failed to analyze tweet');
          console.error(err);
        } finally {
          setLoading(false);
        }
      };
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <div style={styles.container}>
      <div style={{display:'flex', flexDirection:'column'}}>
        <h1 style={styles.header}>Tweet Scraper</h1>
        <label>
            URL på konto, t.ex: https://X.com/SwedishPM &nbsp;
            <input 
                type="text" 
                value={urlTarget} 
                onChange={(e) => setUrlTarget(e.target.value)}
                
            />
        </label>
        <br></br>
        <div className="prompt-input">
          <label htmlFor="prompt-textarea">Prompt för analys av tweetar GPT4o:</label>
          <textarea 
            id="prompt-textarea"
            value={prompt} 
            onChange={(e) => setPrompt(e.target.value)}
            rows={4}
            placeholder="Enter your prompt here..."
          />
        </div>
        {
          tweetData || shouldReset ? <>
          </> : <>
            <button onClick={handleScrapeTweet} style={styles.button}>
              {loading ? 'Skrapar...' : 'Starta skrapning'}
            </button>
          </>
        }
      </div>
      
      
      {error && <p style={styles.error}>{error}</p>}
      {
        snapshotId && <div>
            <p>Fick följande snapshot ID: {snapshotId}</p>
            {/* <button onClick={() => fetchSnapshotData()}>Tryck här för att hämta data för snapshotIDt</button>
            <p>Vänta några sekunder innan du gör detta och tryck inte för många gånger.</p> */}
          </div>
      }
      {
        shouldReset && <><ClipLoader /><p>Hämtar data om {count}</p></>
      }
      {tweetData && (
        <>
        
        <h1>Twitter Feed</h1>
        <TweetList tweets={tweetData} tweetAnalysis={tweetAnalysis}/>
        </>
        // <div style={styles.tweetContainer}>
        //   <h2>{tweetData.core.user_results.result.legacy.name}</h2>
        //   <p>{tweetData.legacy.full_text}</p>
        //   <img src={tweetData.legacy.entities.media[0].media_url_https} alt="Tweet" style={styles.image} />
        // </div>
      )}
    </div>
  );
}

const styles = {
  container: {
    textAlign: 'center',
    padding: '20px',
  },
  header: {
    fontSize: '2rem',
  },
  button: {
    padding: '10px 20px',
    fontSize: '1rem',
    marginTop: '20px',
  },
  tweetContainer: {
    border: '1px solid #ccc',
    padding: '20px',
    marginTop: '20px',
    borderRadius: '10px',
    maxWidth: '600px',
    margin: '20px auto',
    backgroundColor: '#f9f9f9',
  },
  image: {
    maxWidth: '100%',
    borderRadius: '10px',
  },
  error: {
    color: 'red',
  },
};

export default App;

const options = { year: 'numeric', month: 'long', day: 'numeric' };

const TweetCard = ({ tweet }) => {
  return (
    <div className="tweet-card">
      <div className="tweet-header">
        <img src={tweet.profile_image_link} alt="Profile" className="profile-img" />
        <div className="tweet-user-info">
          <h3>{tweet.name}</h3>
          <p>@{tweet.user_posted}</p>
          <p>{new Date(tweet.date_posted).toLocaleDateString('sv-SE', options )}</p>
        </div>
      </div>

      <div className="tweet-body">
        <p>{tweet.description}</p>
      </div>

      <div className="tweet-stats">
        <span>👍 {tweet.likes} Likes</span>
        <span>🔁 {tweet.reposts} Retweets</span>
        <span>💬 {tweet.replies} Replies</span>
        <span>👀 {tweet.views} Views</span>
      </div>
    </div>
  );
};

// The TweetList component that renders a list of tweets as cards
const TweetList = ({ tweets, tweetAnalysis }) => {
  return (
    <div className="tweet-list">
      {tweets.map((tweet, index) => (
          <div style={{display:'flex', width:'800px'}}>
            <TweetCard key={index} tweet={tweet} />
            <p style={{width:'50%'}}>{tweetAnalysis[tweet.id]}</p>
          </div>
      ))}
    </div>
  );
};

const LoadingSpinner = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 100"
    width="50px"
    height="50px"
    fill="none"
    stroke="currentColor"
    strokeWidth="8"
  >
    <circle
      cx="50"
      cy="50"
      r="40"
      strokeOpacity="0.5"
    />
    <path
      d="M50 10 a40 40 0 0 1 0 80 a40 40 0 0 1 0 -80"
      strokeLinecap="round"
    >
      <animateTransform
        attributeName="transform"
        type="rotate"
        from="0 50 50"
        to="360 50 50"
        dur="1s"
        repeatCount="indefinite"
      />
    </path>
  </svg>
);